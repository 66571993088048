<template>
  <div class="container">
    <ContentHeader
      v-bind:title="content_header.title"
      v-bind:subTitle="content_header.subTitle"
    />
    <div class="page__body">
      <div class="form-row">
        <div class="col-md-4 form__item">
          <div class="form-group">
            <label class="form__label">Type</label>
            <select
              class="w-100 select--lg"
              @change="clientTypeSelectChange($event)"
            >
              <option value="null">All</option>
              <option value="Customer">Customer</option>
              <option value="Group">Group</option>
            </select>
          </div>
        </div>
      </div>
      <div class="text-right">
        <button
          class="button page__body__header__button ml-auto button--grey text--capital"
          type="button"
          data-toggle="modal"
          data-target="#newModal"
          v-if="userCanCreateCustomerTypes"
        >
          Add Type <i class="ki ki-plus icon-sm ml-3"> </i>
        </button>
      </div>

      <div class="mt-5">
        <table class="table customtable customtable__templateid">
          <thead class="table__header">
            <tr class="table_header_text">
              <th style="width: 35%">Name</th>
              <th>ID</th>
              <th>ID Template</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody class="table__body">
            <tr
              class="table__text"
              v-for="item in customerTypes"
              v-bind:key="item.customerTypeKey"
            >
              <td style="width: 35%">{{ item.clientTypeName }}</td>
              <td>{{ item.clinetTypeCustomId }}</td>
              <td>{{ item.clientTypeIdPattern }}</td>
              <td>
                <div class="dropdown">
                  <button
                    class="button btn-light dropdown-toggle"
                    type="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="true"
                  >
                    Action
                  </button>
                  <div
                    class="dropdown-menu dropdown-menu-container"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <a
                      href="#"
                      class="dropdown-item"
                      type="button"
                      data-toggle="modal"
                      data-target="#editModal"
                      v-on:click="getCustomerType(item.customerTypeKey)"
                      v-if="userCanEditCustomerTypes"
                      >Edit</a
                    >
                    <a
                      href="#"
                      class="dropdown-item"
                      type="button"
                      data-toggle="modal"
                      data-target="#deleteConfirmModal"
                      v-on:click="openConfirmation(item.customerTypeKey)"
                      v-if="userCanDeleteCustomerTypes"
                      >Delete</a
                    >
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="pagination">
        <div class="d-flex align-items-center justify-content-between w-100">
          <div class="text--capital text--regular pagination__text">
            row:
            <span
              class="d-inline-block text--primary"
              style="padding-right: 15px"
            >
              <select @change="changeLength" v-model="size">
                <option :value="10">10</option>
                <option :value="20">20</option>
                <option :value="30">30</option>
                <option :value="50">50</option>
                <option :value="100">100</option>
              </select>
            </span>
            <span class="d-inline-block text--primary">{{
              !paginations.hasPreviousPage
                ? paginations.pageIndex
                : size * (paginations.pageIndex - 1) + 1
            }}</span>
            -
            <span class="d-inline-block text--primary">{{
              paginations.hasNextPage
                ? size * paginations.pageIndex
                : paginations.totalCount
            }}</span>
            of
            <span>{{ paginations ? paginations.totalCount : 0 }}</span>
          </div>
          <div
            class="pagination__buttons d-flex align-items-center justify-content-between"
            v-if="paginations"
          >
            <button
              class="button button--sm text--regular"
              @click="changePage('previous')"
              :disabled="!paginations.hasPreviousPage"
            >
              <ion-icon name="chevron-back-outline"></ion-icon>
            </button>
            <button
              v-for="page in paginations.totalPages"
              :key="page"
              :class="{
                'text--white button--primary ': paginations.pageIndex === page,
              }"
              class="button text--regular button--sm ml-2"
              @click="changePage(page)"
            >
              {{ page }}
            </button>
            <button
              class="button text--regular button--sm ml-2"
              @click="changePage('next')"
              :disabled="!paginations.hasNextPage"
            >
              <ion-icon name="chevron-forward-outline"></ion-icon>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div class="modal modal__right" id="newModal" aria-hidden="true">
      <div class="">
        <div class="modal__dialog" role="document">
          <div class="modal__content">
            <div class="modal__header">
              <ion-icon
                class="modal__head__icon modal__close modal__close--black"
                name="arrow-back-outline"
                data-dismiss="modal"
                id="formCloseButton"
                aria-label="Close"
                @click="clearForm"
                :disabled="isSaving"
              ></ion-icon>
              <div class="modal__header__title">
                <h5 class="modal__head__title">Add Customer Type</h5>
                <br />
              </div>
            </div>
            <div class="modal__body">
              <form class="form" @submit="saveForm">
                <div class="form__item">
                  <label class="form__label">Name</label>
                  <input
                    v-model="form.clientTypeName"
                    class="input form__input w-100"
                    placeholder="Enter Name"
                    v-bind:class="{ 'input-required': err.clientTypeName }"
                  />
                </div>

                <div class="form__item">
                  <label class="form__label">ID</label>
                  <input
                    v-model="form.clinetTypeCustomId"
                    class="input form__input w-100"
                    placeholder="Enter ID"
                    v-bind:class="{
                      'input-required': err.clinetTypeCustomId,
                    }"
                  />
                </div>

                <div class="form__item">
                  <label class="form__label">
                    Client ID Template
                    <i
                      class="bi bi-info-circle-fill"
                      data-toggle="tooltip"
                      data-placement="top"
                      style="font-size: 1.6rem"
                      :title="'The symbol # represent Numbers (Ex. 1,2,3...)\nThe symbol @ represent Alphabets (Ex. ABCabc)\nThe symbol $ represent AlphaNumeric (Ex. ABCabc123)'"
                    ></i>
                  </label>
                  <input
                    v-model="form.clientTypeIdPattern"
                    class="input form__input w-100"
                    placeholder="Enter Here"
                    v-bind:class="{
                      'input-required': err.clientTypeIdPattern,
                    }"
                  />
                </div>

                <div class="form__item">
                  <label class="form__label">Client Type</label>
                  <select
                    v-model="form.clientType"
                    class="w-100 select--lg"
                    placeholder="Enter Here"
                    v-bind:class="{ 'input-required': err.clientType }"
                  >
                    <option value="Customer">Customer</option>
                    <option value="Group">Group</option>
                  </select>
                </div>

                <div>
                  <label class="form__label">Usage</label>
                  <div class="form__item">
                    <input
                      v-model="form.canOpenAccounts"
                      class="form__checkbox mr-4"
                      type="checkbox"
                    />
                    <label class="form__label">Allow opening accounts</label>
                  </div>
                  <div class="form__item">
                    <input
                      v-model="form.canGuarantee"
                      class="form__checkbox mr-4"
                      type="checkbox"
                    />
                    <label class="form__label">Allow as guarantor</label>
                  </div>

                  <div class="form__item">
                    <input
                      v-model="form.requireIdentification"
                      class="form__checkbox mr-4"
                      type="checkbox"
                    />
                    <label class="form__label"
                      >Require identification documents</label
                    >
                  </div>
                  <div class="form__item">
                    <input
                      v-model="form.useDefaultAddress"
                      class="form__checkbox mr-4"
                      type="checkbox"
                    />
                    <label class="form__label"
                      >Show default address fields</label
                    >
                  </div>
                  <div class="form__item">
                    <input
                      v-model="form.requireBvn"
                      class="form__checkbox mr-4"
                      type="checkbox"
                    />
                    <label class="form__label">Requires Bvn</label>
                  </div>
                  <div class="form__item">
                    <input
                      v-model="form.requireEmailAddress"
                      class="form__checkbox mr-4"
                      type="checkbox"
                    />
                    <label class="form__label">Requires Email</label>
                  </div>
                  <div class="form__item">
                    <input
                      v-model="form.requireEmployer"
                      class="form__checkbox mr-4"
                      type="checkbox"
                    />
                    <label class="form__label">Requires Employer</label>
                  </div>
                </div>

                <div class="form__item">
                  <label class="form__label">Description</label>
                  <div id="content-container">
                    <textarea
                      v-model="form.clientTypeDescription"
                      class="input form__input w-100"
                      label="Description"
                      placeholder=" Description"
                      cols="100"
                      rows="10"
                      v-bind:class="{
                        'input-required': err.clientTypeDescription,
                      }"
                    ></textarea>
                  </div>
                </div>

                <button
                  class="button form__button form__button--lg"
                  type="submit"
                  v-if="!isSaving"
                >
                  Save Changes
                </button>
                <!-- loader button -->
                <a
                  role="button"
                  class="button form__button form__button--lg w-25 save-changes"
                  v-if="isSaving"
                  :disabled="true"
                >
                  <div class="spinner-border text-light" role="status"></div>
                </a>
                <button
                  class="button form__action__text form__action__text--danger ml-5"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="formCloseButton"
                  @click="clearForm"
                  :disabled="isSaving"
                >
                  Cancel
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Modal -->

    <!-- Modal -->
    <div class="modal modal__right" id="editModal" aria-hidden="true">
      <div class="">
        <div class="modal__dialog" role="document">
          <div class="modal__content">
            <div class="modal__header" v-if="!isLoading">
              <ion-icon
                class="modal__head__icon modal__close modal__close--black"
                name="arrow-back-outline"
                data-dismiss="modal"
                id="editformCloseButton"
                aria-label="Close"
                @click="clearForm"
                :disabled="isSaving"
              ></ion-icon>
              <div class="modal__header__title">
                <h5 class="modal__head__title">
                  Edit
                  {{
                    form.clientType == "Group" ? "Group Type" : "Customer Type"
                  }}
                </h5>
                <br />
              </div>
            </div>
            <div class="modal__body" v-if="customerType != null">
              <form class="form" @submit="saveEditForm">
                <div class="form__item">
                  <label class="form__label">Name</label>
                  <input
                    v-model="form.clientTypeName"
                    class="input form__input w-100"
                    placeholder="Enter Name"
                    v-bind:class="{ 'input-required': err.clientTypeName }"
                  />
                </div>

                <div class="form__item">
                  <label class="form__label">ID</label>
                  <input
                    v-model="form.clinetTypeCustomId"
                    class="input form__input w-100"
                    placeholder="Enter ID"
                    v-bind:class="{
                      'input-required': err.clinetTypeCustomId,
                    }"
                  />
                </div>

                <div class="form__item">
                  <label class="form__label">
                    Client ID Template
                    <i
                      class="bi bi-info-circle-fill"
                      data-toggle="tooltip"
                      data-placement="top"
                      style="font-size: 1.6rem"
                      :title="'The symbol # represent Numbers (Ex. 1,2,3...)\nThe symbol @ represent Alphabets (Ex. ABCabc)\nThe symbol $ represent AlphaNumeric (Ex. ABCabc123)'"
                    ></i>
                  </label>
                  <input
                    v-model="form.clientTypeIdPattern"
                    class="input form__input w-100"
                    placeholder="Enter Here"
                    v-bind:class="{
                      'input-required': err.clientTypeIdPattern,
                    }"
                  />
                </div>

                <div class="form__item">
                  <label class="form__label">Client Type</label>
                  <select
                    v-model="form.clientType"
                    class="w-100 select--lg"
                    placeholder="Enter Here"
                    v-bind:class="{ 'input-required': err.clientType }"
                  >
                    <option value="Customer">Customer</option>
                    <option value="Group">Group</option>
                  </select>
                </div>

                <div>
                  <label class="form__label">Usage</label>
                  <div class="form__item">
                    <input
                      v-model="form.canOpenAccounts"
                      class="form__checkbox mr-4"
                      type="checkbox"
                    />
                    <label class="form__label">Allow opening accounts</label>
                  </div>
                  <div class="form__item">
                    <input
                      v-model="form.canGuarantee"
                      class="form__checkbox mr-4"
                      type="checkbox"
                    />
                    <label class="form__label">Allow as guarantor</label>
                  </div>

                  <div class="form__item">
                    <input
                      v-model="form.requireIdentification"
                      class="form__checkbox mr-4"
                      type="checkbox"
                    />
                    <label class="form__label"
                      >Require identification documents</label
                    >
                  </div>
                  <div class="form__item">
                    <input
                      v-model="form.useDefaultAddress"
                      class="form__checkbox mr-4"
                      type="checkbox"
                    />
                    <label class="form__label"
                      >Show default address fields</label
                    >
                  </div>
                  <div class="form__item">
                    <input
                      v-model="form.requireBvn"
                      class="form__checkbox mr-4"
                      type="checkbox"
                    />
                    <label class="form__label">Requires Bvn</label>
                  </div>
                  <div class="form__item">
                    <input
                      v-model="form.requireEmailAddress"
                      class="form__checkbox mr-4"
                      type="checkbox"
                    />
                    <label class="form__label">Requires Email</label>
                  </div>
                  <div class="form__item">
                    <input
                      v-model="form.requireEmployer"
                      class="form__checkbox mr-4"
                      type="checkbox"
                    />
                    <label class="form__label">Requires Employer</label>
                  </div>
                </div>

                <div class="form__item">
                  <label class="form__label">Description</label>
                  <div id="content-container">
                    <textarea
                      v-model="form.clientTypeDescription"
                      class="input form__input w-100"
                      label="Description"
                      placeholder=" Description"
                      cols="100"
                      rows="10"
                      v-bind:class="{
                        'input-required': err.clientTypeDescription,
                      }"
                    ></textarea>
                  </div>
                </div>

                <button
                  class="button form__button form__button--lg"
                  type="submit"
                  v-if="!isSaving"
                >
                  Save Changes
                </button>
                <!-- loader button -->
                <a
                  role="button"
                  class="button form__button form__button--lg w-25 save-changes"
                  v-if="isSaving"
                  :disabled="true"
                >
                  <div class="spinner-border text-light" role="status"></div>
                </a>
                <button
                  class="button form__action__text form__action__text--danger ml-5"
                  data-dismiss="modal"
                  aria-label="Close"
                  @click="close"
                >
                  Cancel
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Modal -->

    <!-- Modal -->
    <div
      class="modal modal__confirm"
      id="deleteConfirmModal"
      aria-hidden="true"
    >
      <ion-icon
        class="modal__head__icon modal__close"
        name="close-outline"
        data-dismiss="modal"
        aria-label="Close"
      ></ion-icon>
      <div class="">
        <div class="modal__dialog" role="document">
          <div class="modal__head">
            <div
              class="modal__close modal__head__icon modal__close--white"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span> <ion-icon name="close-outline"></ion-icon> Close</span>
            </div>

            <h5 class="modal__header__title">Confirmation</h5>
          </div>
          <div class="modal__content">
            <div class="modal__header">
              <div class="modal__header__title">
                <h5 class="modal__head__title">
                  Are you sure you want to delete this Customer Type ?
                </h5>
              </div>
              <div class="modal__header__subtitle">
                <h6 class="my-4">
                  When you delete this Customer Type from your list, you won't
                  be able to access it again,
                </h6>
              </div>
            </div>

            <div>
              <button
                class="button form__button form__button--lg mr-5 modal__confirm__button modal__confirm__button--cancel"
                data-dismiss="modal"
                aria-label="Close"
                id="closeButton"
              >
                Cancel
              </button>
              <!-- loader button -->
              <a
                role="button"
                class="button form__button form__button--lg w-25 modal__confirm__button modal__confirm__button--delete"
                v-if="isDeleting"
                :disabled="true"
              >
                <div class="spinner-border text-light" role="status"></div>
              </a>
              <button
                class="button form__button form__button--lg modal__confirm__button modal__confirm__button--delete"
                type="submit"
                v-if="!isDeleting"
                v-on:click="deleteCustomerType(deleteKey)"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Modal -->
    <!--ALERT NOTIFICATION-->
    <div class="toaster show">
      <div
        class="toaster__container"
        v-if="alert.show"
        v-bind:class="{
          'toaster--success': alert.status === 'success',
          'toaster--error': alert.status === 'fail',
          'toaster--warn': alert.status === 'warn',
        }"
      >
        <div class="text-right">
          <span class="fa fa-times" @click="alert.show = false"></span>
        </div>
        <div>
          <span class="d-block text--small text--600 text--white">{{
            alert.title
          }}</span>
          <span class="d-block text--tiny text--400 text--white">{{
            alert.description
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { Role } from "@/core/services/role.js";
import { mapState } from "vuex";
import customerTypeService from "@/core/services/general-setup-service/customer-type.service";
import { GET_CUSTOMER_TYPES } from "@/core/services/store/types";
export default Vue.extend({
  name: "customer-types",
  data: function () {
    return {
      content_header: {
        title: "Customer Types",
        subTitle: "",
        // subTitle: "Set up organization's customer types.",
      },
      deleteKey: null,
      isLoading: false,
      isSaving: false,
      isDeleting: false,
      alert: {
        show: false,
        status: "Success",
        title: "Success",
        description: "",
      },
      fetchModel: {
        pageSize: 10,
        pageIndex: 1,
        clientType: null,
        clientTypeName: null,
        clientTypeDescription: null,
        clientTypeIdPattern: null,
        clinetTypeCustomId: null,
        clientTypeIndex: 0,
        canGuarantee: null,
        canOpenAccounts: null,
        useDefaultAddress: null,
        requireIdentification: null,
        requireBvn: null,
        requireEmailAddress: null,
        requireEmployer: null,
      },

      form: {
        clientType: null,
        clientTypeName: null,
        clientTypeDescription: null,
        clientTypeIdPattern: null,
        clinetTypeCustomId: null,
        clientTypeIndex: 0,
        canGuarantee: true,
        canOpenAccounts: true,
        useDefaultAddress: true,
        requireIdentification: true,
        requireBvn: false,
        requireEmailAddress: false,
        requireEmployer: false,
      },
      err: {
        clientType: false,
        clientTypeName: false,
        clientTypeDescription: false,
        clientTypeIdPattern: false,
        clinetTypeCustomId: false,
        clientTypeIndex: false,
      },
      customerTypes: [],
      customerType: null,
      paginations: {},
      pages: 1,
      size: 20,
    };
  },
  methods: {
    close() {
      document.getElementById("editformCloseButton").click();
      this.clearForm();
    },
    async getCustomerTypes() {
      try {
        this.customerTypes = [];
        this.isLoading = true;
        this.fetchModel.pageIndex = this.pages;
        this.fetchModel.pageSize = this.size;
        const res = await customerTypeService.get(this.fetchModel);
        this.customerTypes = res.data.data.items;
        this.paginations = res.data.data;
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        const error = e.response.data.message;
        this.alertError(error);
      }
    },
    changePage(type) {
      if (type === "next") {
        this.pages++;
      } else if (type === "previous") {
        this.pages--;
      } else {
        this.pages = type;
      }

      this.getCustomerTypes();
    },

    changeLength(e) {
      const { value } = e.target;
      this.size = parseInt(value);
      this.pages = 1;
      this.getCustomerTypes();
    },
    async getCustomerType(key) {
      try {
        this.isLoading = true;
        const res = await customerTypeService.getByKey(key);
        this.customerType = res.data.data;
        this.form.clientType = this.customerType.clientType;
        this.form.clientTypeName = this.customerType.clientTypeName;
        this.form.clientTypeDescription =
          this.customerType.clientTypeDescription;
        this.form.clientTypeIdPattern = this.customerType.clientTypeIdPattern;
        this.form.clinetTypeCustomId = this.customerType.clinetTypeCustomId;
        this.form.clientTypeIndex = this.customerType.clientTypeIndex;
        this.form.canGuarantee = this.customerType.canGuarantee;
        this.form.canOpenAccounts = this.customerType.canOpenAccounts;
        this.form.useDefaultAddress = this.customerType.useDefaultAddress;
        this.form.requireIdentification =
          this.customerType.requireIdentification;
        this.form.requireBvn = this.customerType.requireBvn;
        this.form.requireEmailAddress = this.customerType.requireEmailAddress;
        this.form.requireEmployer = this.customerType.requireEmployer;

        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        const error = e.response.data.message;
        this.alertError(error);
      }
    },

    saveForm: async function (e) {
      e.preventDefault();
      if (this.validateForm()) {
        try {
          this.isSaving = true;
          await customerTypeService.create(this.form);
          this.getCustomerTypes(this.fetchModel);
          this.$store.dispatch(GET_CUSTOMER_TYPES);
          document.getElementById("formCloseButton").click();
          this.alertSuccess("New customer type created");
          this.clearForm();
          this.isSaving = false;
          //call fetch to fetch
        } catch (e) {
          this.isSaving = false;
          const error = e.response.data.message;
          this.alertError(error);
        }
      } else {
        this.alertWarning("Some form fields are required.");
      }
    },
    saveEditForm: async function (e) {
      e.preventDefault();
      if (this.validateForm()) {
        try {
          this.isSaving = true;

          this.form.customerTypeKey = this.customerType.customerTypeKey;
          await customerTypeService.update(
            this.customerType.customerTypeKey,
            this.form
          );
          this.getCustomerTypes(this.fetchModel);
          this.$store.dispatch(GET_CUSTOMER_TYPES);
          document.getElementById("editformCloseButton").click();
          this.alertSuccess("Customer type updated");
          this.clearForm();
          this.isSaving = false;
        } catch (e) {
          this.isSaving = false;
          const error = e.response.data.message;
          this.alertError(error);
        }
      } else {
        this.alertWarning("Some form fields are required.");
      }
    },
    validateForm() {
      this.clearValidation();
      if (
        this.form.clientTypeName &&
        this.form.clientTypeIdPattern &&
        this.form.clinetTypeCustomId &&
        this.form.clientType
      ) {
        return true;
      }

      if (!this.form.clientType) {
        this.err.clientType = true;
      }
      if (!this.form.clientTypeName) {
        this.err.clientTypeName = true;
      }
      if (!this.form.clientTypeDescription) {
        this.err.clientTypeDescription = true;
      }
      if (!this.form.clientTypeIdPattern) {
        this.err.clientTypeIdPattern = true;
      }
      if (!this.form.clinetTypeCustomId) {
        this.err.clinetTypeCustomId = true;
      }
      if (this.form.clientTypeIndex < 0) {
        this.err.clientTypeIndex = true;
      }

      return false;
    },
    clearValidation() {
      this.err = {
        clientType: false,
        clientTypeName: false,
        clientTypeDescription: false,
        clientTypeIdPattern: false,
        clinetTypeCustomId: false,
        clientTypeIndex: false,
      };
    },
    clearForm() {
      this.form = {
        clientType: null,
        clientTypeName: null,
        clientTypeDescription: null,
        clientTypeIdPattern: null,
        clinetTypeCustomId: null,
        clientTypeIndex: 0,
        canGuarantee: true,
        canOpenAccounts: true,
        useDefaultAddress: true,
        requireIdentification: true,
        requireBvn: false,
        requireEmailAddress: false,
        requireEmployer: false,
      };
      this.customerType = null;
      return true;
    },
    clientTypeSelectChange(event) {
      const clientType = event.target.value;
      if (clientType == null || clientType == "null") {
        this.fetchModel.clientType = null;
        this.fetchModel.pageIndex = 1;
        this.getCustomerTypes();
      } else {
        this.fetchModel.clientType = clientType;
        this.fetchModel.pageIndex = 1;
        this.getCustomerTypes();
      }
    },

    async deleteCustomerType(key) {
      this.isDeleting = true;
      try {
        await customerTypeService.delete(key);
        this.alertSuccess("Deleted");
        document.getElementById("closeButton").click();
        this.isDeleting = false;
        this.getCustomerTypes(this.fetchModel);
        this.$store.dispatch(GET_CUSTOMER_TYPES);
      } catch (e) {
        this.isDeleting = false;
        const error = e.response.data.message;
        this.alertError(error);
      }
    },
    openConfirmation(key) {
      this.deleteKey = key;
    },
    alertSuccess(description) {
      this.alert.status = "success";
      this.alert.title = "Success";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertError(description) {
      this.alert.status = "fail";
      this.alert.title = "Error";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertWarning(description) {
      this.alert.status = "warn";
      this.alert.title = "Warning";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
  },
  async created() {
    this.getCustomerTypes();
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    userCanCreateCustomerTypes() {
      const customerType = this.user.scopes.find(
        (item) => item.scopeName === Role.GeneralSetup
      );
      const { permissionsList } = customerType;
      return permissionsList.CanCreateCustomerTypes;
    },
    userCanEditCustomerTypes() {
      const customerType = this.user.scopes.find(
        (item) => item.scopeName === Role.GeneralSetup
      );
      const { permissionsList } = customerType;
      return permissionsList.CanEditCustomerTypes;
    },
    userCanDeleteCustomerTypes() {
      const customerType = this.user.scopes.find(
        (item) => item.scopeName === Role.GeneralSetup
      );
      const { permissionsList } = customerType;
      return permissionsList.CanDeleteCustomerTypes;
    },
  },
});
</script>
